import React from "react";
import SignUp from "/src/containers/signup";
import Navbar from "/src/containers/navbar";

import config from "/src/config.js";
import SEO from "/src/components/seo";
import BacThemeProvider from "/src/providers/bac-theme-provider";

const SignUpPage = () => {
  return (
    <>
      <SEO
        title={`${config.CLIENT_NAME} - Sign up`}
        description={config.CLIENT_DESCRIPTION}
        image={encodeURI(config.LOGO_DARK) || ""}
      />
      <BacThemeProvider>
        <Navbar />
        <SignUp />
      </BacThemeProvider>
    </>
  );
};

export default SignUpPage;
