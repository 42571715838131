import { Box, Button, Container, Grid, MenuItem, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import React, { useState, useEffect, useCallback, useRef } from "react";
import { ValidatorForm } from "react-material-ui-form-validator";
import { Trans, t } from "@lingui/macro";
import { useLingui } from "@lingui/react";
import Select from "../select/select-validator";
// theme
import theme from "../../theme";
// local components
import Link from "../link";
import TextFieldAlternative from "../text-field/text-field-with-label";
import TextFieldPhone from "../text-field/text-field-phone";
import CheckBox from "../custom-checkbox/custom-checkbox-alternative-3";
import PrivacyPolicy from "../privacy-policy";
import { debounce } from "../../utils";

const useStyles = makeStyles((theme) => ({
  container: {
    padding: 0,
    marginBottom: theme.spacing(3),
    display: "flex",
    justifyContent: "center",
  },
  box: {
    width: "600px",
    marginTop: theme.spacing(3),
    textAlign: "center",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
  },
  form: {
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(3),
    paddingTop: theme.spacing(2),
    "& .MuiTextField-root": {
      marginTop: theme.spacing(0),
    },
  },
  formRow: {
    [theme.breakpoints.up("sm")]: {
      gap: theme.spacing(2),
    },
    [theme.breakpoints.down("sm")]: {
      display: "flex",
      flexDirection: "column",
    },
  },
  buttonContainer: {
    display: "flex",
    justifyContent: "center",
    marginTop: theme.spacing(2),
    width: "100%",
  },
  links: {
    display: "flex",
    justifyContent: "center",
    alignItems: "baseline",
    marginTop: theme.spacing(2),
  },
  link: {
    cursor: "pointer",
  },
  userType: {
    marginTop: theme.spacing(2),
    justifyContent: "flex-start",
  },
  inputWrapper: {
    flex: 1,
    [theme.breakpoints.down("sm")]: {
      flex: 0,
      marginTop: theme.spacing(2),
    },
  },
  inputs: {
    width: "100%",
    height: "38px",
    borderRadius: 0,
    border: theme.borders[1],
    paddingTop: theme.spacing(1 / 2),
    paddingBottom: theme.spacing(1 / 2),
    paddingLeft: theme.spacing(2),
    textAlign: "left",
  },
  options: {
    width: "100%",
    display: "flex",
    position: "relative",
    boxSizing: "border-box",
    textAlign: "left",
    alignItems: "center",
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
    paddingLeft: theme.spacing(1),
    justifyContent: "flex-start",
    textDecoration: "none",
  },
  text: {
    [theme.breakpoints.down("sm")]: {
      fontSize: 12,
    },
  },
}));

const INITIAL_VALUES = {
  name: "",
  lastname: "",
  email: "",
  password: "",
  password2: "",
  phone: "",
  taxId: "",
  companyId: 0,
  billingAddress: "",
  isAgency: false,
  termsConditions: false,
};

const SignUpComponent = ({
  countries = [],
  registeredCompany = [],
  invitedUser,
  checkCompany,
  registerCompany,
  registerUser,
  searchCompanyStatus,
  registerCompanyStatus,
  registerUserStatus,
  registerCompanyError,
  registerUserError,
  showBillingAddress,
}) => {
  const classes = useStyles(theme);
  const [values, setValues] = useState(INITIAL_VALUES);
  const [step, setStep] = useState(1);
  const [country, setCountry] = useState(null);
  const [company, setCompany] = useState("");
  const [companyExists, setCompanyExists] = useState(false);
  const [taxId, setTaxId] = useState("");
  const [disabledSubmit, setDisabledSubmit] = useState(true);
  const [openPrivacyPolicy, setOpenPrivacyPolicy] = useState(false);

  const formRef = useRef(null);

  const { i18n } = useLingui();

  useEffect(() => {
    checkCompany({ taxId: values.taxId, countryId: country?.id });
  }, [values.taxId]);

  useEffect(() => {
    if (invitedUser) {
      setValues(invitedUser);
      setStep(2);
    }
  }, [invitedUser]);

  useEffect(() => {
    if (registeredCompany) {
      setCompany(registeredCompany.name);
      setValues({ ...values, companyId: registeredCompany.id, billingAddress: registeredCompany.billing_address });
      setCompanyExists(true);
    } else {
      setCompany("");
      setValues({ ...values, companyId: INITIAL_VALUES.companyId, billingAddress: INITIAL_VALUES.billingAddress });
      setCompanyExists(false);
    }
  }, [registeredCompany]);

  useEffect(() => {
    if (country === null) {
      setCompany("");
      setValues({ ...values, taxId: "", companyId: 0, billingAddress: "" });
    }
  }, [country]);

  useEffect(() => {
    if (registerCompanyStatus === "success") {
      setStep(2);
    }
  }, [registerCompanyStatus]);

  useEffect(() => {
    if (countries.length) setCountry(countries[0]);
  }, [countries]);

  const validateForm = async () => {
    const isValid = await formRef.current.isFormValid();

    const stateBtn =
      (step === 2 && !values.termsConditions) || !isValid || registerUserStatus === "loading" || company.length <= 0;
    setDisabledSubmit(stateBtn);
  };

  useEffect(() => {
    ValidatorForm.removeValidationRule("isPasswordMatch");
    ValidatorForm.addValidationRule("isPasswordMatch", (value) => {
      if (value != values.password) {
        return false;
      }
      return true;
    });

    validateForm();

    return () => {
      ValidatorForm.removeValidationRule("isPasswordMatch");
    };
  }, [values, step]);

  const handleCheckboxChange = (field) => {
    setValues((state) => ({ ...state, [field]: !state[field] }));
  };

  const handleInputChange =
    (field) =>
    ({ target }) => {
      setValues((state) => ({ ...state, [field]: target.value }));
    };

  const setValueTaxId = (taxIdFormatted) => setValues((state) => ({ ...state, taxId: taxIdFormatted }));
  const debouncedChangeHandler = useCallback(debounce(setValueTaxId), []);

  const handleTaxInputChange = ({ target }) => {
    let taxIdFormatted = target.value.toUpperCase();
    taxIdFormatted = taxIdFormatted.replace(/[^a-zA-Z0-9]/g, "");
    setTaxId(taxIdFormatted);
    debouncedChangeHandler(taxIdFormatted);
  };

  const handlePhoneInput = (value) => {
    setValues((v) => ({ ...v, phone: value }));
  };

  const handleRegisterCompany = () => {
    if (registeredCompany) {
      setStep(2);
    } else {
      registerCompany({
        name: company,
        thumbUrl: "",
        taxId: values.taxId,
        countryId: country.id,
        isAgency: values.isAgency,
        billingAddress: values.billingAddress,
      });
    }
  };

  const handleRegisterUser = () => {
    registerUser({
      name: values.name,
      lastname: values.lastname,
      phone: values.phone,
      email: values.email,
      password: values.password,
      passwordConfirmation: values.password2,
      companyId: values.companyId || registeredCompany.id,
    });
  };

  const handleBack = () => {
    setStep(1);
  };

  return (
    <>
      <PrivacyPolicy open={openPrivacyPolicy} onClose={() => setOpenPrivacyPolicy(false)} />
      <Container className={classes.container}>
        <Box className={classes.box}>
          <Typography variant="h3" style={{ color: theme.palette.black }}>
            <Trans>CREAR UNA NUEVA CUENTA</Trans>
          </Typography>
          <Box mt={2} pl={2} pr={2}>
            {step === 1 && (
              <Typography variant="h6" style={{ color: theme.palette.gray96 }}>
                <Trans>Paso 1 de 2: Por favor completa los siguientes datos</Trans>
              </Typography>
            )}
            {step === 2 && (
              <Typography variant="h6" style={{ color: theme.palette.gray96 }}>
                <Trans>Paso 2 de 2: Ingresa tus datos personales</Trans>
              </Typography>
            )}
          </Box>
          <ValidatorForm
            ref={formRef}
            validateOnMount
            onSubmit={() => {
              handleRegisterUser();
            }}
            className={classes.form}
          >
            {step === 1 && (
              <Box display="flex" flexDirection="column">
                <Box display="flex" className={classes.formRow}>
                  <Box className={classes.inputWrapper}>
                    <Select
                      label={`${i18n._(t`País`)}`}
                      InputLabelProps={{
                        className: `${classes.inputLabel}`,
                      }}
                      InputProps={{
                        className: `${classes.inputs}`,
                        placeholder: `${i18n._(t`País`)}`,
                      }}
                      value={country?.id ? country?.id : ""}
                      onChange={({ target }) => {
                        setCountry(countries.find((c) => c.id === target.value));
                      }}
                      fullWidth
                    >
                      {countries.map((a) => (
                        <MenuItem key={a.id} value={a.id} className={classes.options}>
                          {a.name}
                        </MenuItem>
                      ))}
                    </Select>
                  </Box>
                  <Box className={classes.inputWrapper}>
                    <TextFieldAlternative
                      label={`${i18n._(t`Tax ID`)}`}
                      disabled={country === null || registerCompanyStatus === "loading"}
                      value={taxId}
                      onChange={handleTaxInputChange}
                      validators={["required"]}
                      fullWidth
                    />
                  </Box>
                </Box>

                {country?.tax_id_label === "ABN" && (
                  <Box mt={2}>
                    <Typography variant="caption">
                      <Trans>Por favor verifica el ABN</Trans>
                      <b style={{ marginLeft: 3 }}>
                        <a href="https://abr.business.gov.au/" target="_blank" rel="noreferrer">
                          <Trans>aqui</Trans>
                        </a>
                      </b>
                    </Typography>
                  </Box>
                )}

                <Box mt={2}>
                  <TextFieldAlternative
                    label={`${i18n._(t`Empresa`)}`}
                    disabled={
                      registerCompanyStatus === "loading" ||
                      searchCompanyStatus === "loading" ||
                      companyExists ||
                      country === null
                    }
                    value={company}
                    onChange={(e) => {
                      setCompany(e.target.value.toUpperCase());
                    }}
                    validators={["required"]}
                    errorMessages={[`${i18n._(t`completar el campo`)}`]}
                    fullWidth
                    autoComplete="new-password"
                  />
                </Box>

                {showBillingAddress && (
                  <Box mt={2}>
                    <TextFieldAlternative
                      label={`${i18n._(t`Dirección de facturación`)}`}
                      disabled={
                        registerCompanyStatus === "loading" ||
                        searchCompanyStatus === "loading" ||
                        (companyExists && values.billingAddress) ||
                        country === null
                      }
                      value={values.billingAddress}
                      onChange={handleInputChange("billingAddress")}
                      fullWidth
                    />
                  </Box>
                )}

                {!companyExists && (
                  <Box>
                    <Box height="50px" display="flex" flexDirection="row" alignItems="center" justifyContent="center">
                      <CheckBox
                        checked={values?.isAgency}
                        onChange={() => {
                          handleCheckboxChange("isAgency");
                        }}
                      />
                      <Typography variant="body1" className={classes.text}>
                        <Trans>Si tu empresa es una agencia, selecciona la casilla</Trans>
                      </Typography>
                    </Box>
                  </Box>
                )}
                {registerCompanyStatus === "error" && (
                  <Box>
                    {registerCompanyError.map((err) => (
                      <Typography variant="body1" style={{ color: theme.palette.error.main }}>
                        {err}
                      </Typography>
                    ))}
                  </Box>
                )}
                <Box>
                  <Box className={classes.buttonContainer}>
                    <Box width="200px">
                      <Button
                        disabled={
                          registerCompanyStatus === "loading" ||
                          company?.length <= 0 ||
                          !country ||
                          values.taxId.length <= 0 ||
                          (showBillingAddress && !values.billingAddress)
                        }
                        size="large"
                        variant="contained"
                        color="primary"
                        onClick={handleRegisterCompany}
                        className={classes.submit}
                        fullWidth
                      >
                        {registerCompanyStatus === "loading" ? (
                          <Trans>Cargando....</Trans>
                        ) : (
                          <>{companyExists ? <Trans>CONTINUAR</Trans> : <Trans>DAR DE ALTA</Trans>}</>
                        )}
                      </Button>
                    </Box>
                  </Box>
                </Box>
              </Box>
            )}
            {step === 2 && (
              <Grid container spacing={2}>
                <Grid item md={12}>
                  <Typography variant="subtitle1" style={{ color: theme.palette.black }}>
                    {registeredCompany?.name}
                  </Typography>
                </Grid>
                <Grid item md={6} xs={12}>
                  <TextFieldAlternative
                    label={`${i18n._(t`Nombre`)}`}
                    placeholder={`${i18n._(t`Ingresa tu nombre`)}`}
                    value={values.name}
                    onChange={handleInputChange("name")}
                    validators={["required", "matchRegexp:^([a-zA-Z]+[' ']{0,1})+$"]}
                    errorMessages={[`${i18n._(t`Este campo es obligatorio`)}`, `${i18n._(t`Solo admite letras`)}`]}
                    fullWidth
                    autoComplete="new-password"
                  />
                </Grid>
                <Grid item md={6} xs={12}>
                  <TextFieldAlternative
                    label={`${i18n._(t`Apellidos`)}`}
                    placeholder={`${i18n._(t`Ingresa tus apellidos`)}`}
                    value={values.lastname}
                    onChange={handleInputChange("lastname")}
                    validators={["required", "matchRegexp:^([a-zA-Z]+[' ']{0,1})+$"]}
                    errorMessages={[`${i18n._(t`Este campo es obligatorio`)}`, `${i18n._(t`Solo admite letras`)}`]}
                    fullWidth
                    autoComplete="new-password"
                  />
                </Grid>
                <Grid item md={6} xs={12}>
                  <TextFieldAlternative
                    label={`${i18n._(t`Dirección de Email`)}`}
                    placeholder={`${i18n._(t`Ingresa un Email (será tu usuario)`)}`}
                    value={values.email}
                    type="email"
                    onChange={handleInputChange("email")}
                    validators={["required", "isEmail"]}
                    errorMessages={[`${i18n._(t`Necesitamos un e-mail`)}`, `${i18n._(t`El e-mail no es válido`)}`]}
                    fullWidth
                    autoComplete="new-password"
                  />
                </Grid>
                <Grid item md={6} xs={12}>
                  <TextFieldPhone
                    label={`${i18n._(t`Teléfono`)}`}
                    value={values.phone}
                    onChange={handlePhoneInput}
                    disableAreaCodes
                    fullWidth
                    required
                  />
                </Grid>
                <Grid item md={6} xs={12}>
                  <TextFieldAlternative
                    label={`${i18n._(t`Contraseña`)}`}
                    name="password"
                    type="password"
                    placeholder={`${i18n._(t`Genera una contraseña`)}`}
                    value={values.password}
                    onChange={handleInputChange("password")}
                    validators={["required", "matchRegexp:^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9]).{8,24}$"]}
                    errorMessages={[
                      `${i18n._(t`Este campo es obligatorio`)}`,
                      `${i18n._(
                        t`La contraseña debe tener entre 8 y 24 caracteres y contener al menos un número, una letra minúscula y una mayúscula.`,
                      )}`,
                    ]}
                    autoComplete="new-password"
                    fullWidth
                  />
                </Grid>
                <Grid item md={6} xs={12}>
                  <Box height={16} />
                  <TextFieldAlternative
                    label={` `}
                    name="repeatPassword"
                    type="password"
                    placeholder={`${i18n._(t`Confirma la contraseña`)}`}
                    value={values.password2}
                    onChange={handleInputChange("password2")}
                    validators={["required", "isPasswordMatch"]}
                    errorMessages={[`${i18n._(t`Este campo es obligatorio`)}`, `${i18n._(t`Contraseña no coincide`)}`]}
                    autoComplete="new-password"
                    fullWidth
                  />
                </Grid>
                <Grid item xs={12}>
                  <Box display="flex" flexDirection="row" alignItems="center" justifyContent="center">
                    <CheckBox
                      checked={values.termsConditions}
                      onChange={() => {
                        handleCheckboxChange("termsConditions");
                      }}
                    />
                    <Box>
                      <Typography variant="body1" className={classes.text}>
                        <Trans>Estoy de acuerdo con la</Trans>
                      </Typography>
                    </Box>
                    <Box ml={1 / 2} onClick={() => setOpenPrivacyPolicy(true)} className={classes.link}>
                      <Typography variant="body1" color="primary" className={classes.text}>
                        <Trans>política de privacidad</Trans>
                      </Typography>
                    </Box>
                  </Box>
                </Grid>
                <Grid item xs={12}>
                  <Box className={classes.buttonContainer}>
                    <Box width="200px" mr={2}>
                      <Button
                        disabled={registerUserStatus === "loading"}
                        size="large"
                        variant="contained"
                        color="secondary"
                        className={classes.submit}
                        fullWidth
                        onClick={handleBack}
                      >
                        <Trans>Volver</Trans>
                      </Button>
                    </Box>
                    <Box width="200px">
                      <Button
                        disabled={disabledSubmit}
                        size="large"
                        variant="contained"
                        color="primary"
                        className={classes.submit}
                        type="submit"
                        fullWidth
                      >
                        {registerUserError === "loading" ? <Trans>Cargando....</Trans> : <Trans>REGISTRARME</Trans>}
                      </Button>
                    </Box>
                  </Box>
                </Grid>
              </Grid>
            )}
            <Box mt={1}>
              {registerUserStatus === "error" && (
                <>
                  {registerUserError.map((err) => (
                    <Box>
                      <Typography variant="body1" color="error">
                        {i18n._(t`${err}`)}
                      </Typography>
                    </Box>
                  ))}
                </>
              )}
            </Box>
          </ValidatorForm>
          <Box className={classes.links} mt={2} mb={2}>
            <Box marginX={1 / 2}>
              <Trans>¿Ya estás registrado?</Trans>
            </Box>
            <Link href="/signin/">
              <Typography variant="body1" style={{ textDecoration: "underline" }}>
                <Trans>Inicia Sesión</Trans>
              </Typography>
            </Link>
          </Box>
        </Box>
      </Container>
    </>
  );
};

export default SignUpComponent;
