import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";
import api from "../../api";
import apiAuth from "../../api/auth";
import { getCountriesThunk, getFeatureFlagsThunk } from "../../redux/actions/misc-actions";
import { loginThunk, urlRedirect } from "../../redux/actions/auth-actions";
import { useNavigate } from "/src/hooks/navigation";
import SignUpComponent from "../../components/signup";
import { useQuery } from "../../utils";

function SignUp({ onSubmit, isPopup }) {
  const dispatch = useDispatch();
  const { navigate } = useNavigate();
  const query = useQuery();

  const [registeredCompany, setRegisteredCompany] = useState("");
  const [searchCompanyStatus, setSearchCompanyStatus] = useState("initial");
  const [registerCompanyStatus, setRegisterCompanyStatus] = useState("initial");
  const [registerUserStatus, setRegisterUserStatus] = useState("initial");
  const [registerCompanyError, setRegisterCompanyError] = useState([]);
  const [registerUserError, setRegisterUserError] = useState([]);
  const [invitedUser, setInvitedUser] = useState();
  const showBillingAddress = useSelector(
    (state) => state.misc.featureFlags["flag.exp.show_address_fields_on_register"],
  );

  const countries = useSelector((state) => state.misc.countries);
  const url = useSelector((state) => state.user.urlRedirect);
  const isAuthenticated = useSelector((state) => state.user.isAuthenticated);

  const tenant = query.get("tenant");

  useEffect(async () => {
    dispatch(getCountriesThunk());
    dispatch(getFeatureFlagsThunk());
    if (tenant) {
      const url = window.atob(tenant);
      const invitationRequest = await axios.get(decodeURI(url));
      const data = invitationRequest.data?.payload;

      if (data?.company) {
        setRegisteredCompany(data.company);
        setInvitedUser(data);
      }
    }
  }, []);

  useEffect(() => {
    if (url && isAuthenticated) {
      dispatch(urlRedirect(""));
      navigate(url);
      return;
    }
    if (isAuthenticated && !isPopup) {
      navigate("/");
    }
  }, [isAuthenticated]);

  const handleCheckCompany = async ({ taxId }) => {
    if (!taxId) {
      return [];
    }

    setSearchCompanyStatus("loading");

    const res = await api.getCompanyByTaxId(taxId);

    if (res.status === 200) {
      const company = res.data?.payload?.length > 0 ? res.data?.payload[0] : null;
      setRegisteredCompany(company);
      setSearchCompanyStatus("success");
    } else {
      setSearchCompanyStatus("error");
    }
  };

  const handleRegisterCompany = async ({
    name,
    thumbUrl,
    taxId,
    countryId,
    isAgency,
    billingAddress,
    serviceAddress,
  }) => {
    setRegisterCompanyStatus("loading");
    const res = await api.registerCompany({
      name,
      thumbUrl,
      taxId,
      countryId,
      isAgency,
      billingAddress,
      serviceAddress,
    });

    if (res.response) {
      const messages = res.response?.data?.message;
      const errors = [];
      setRegisterCompanyStatus("error");
      if (Array.isArray(messages)) {
        Object.keys(messages).map((k) => {
          errors.push(messages[k][0]);
        });
      } else {
        errors.push(messages);
      }
      setRegisterCompanyError(errors);
      return;
    }
    setRegisteredCompany(res.data?.payload);
    setRegisterCompanyStatus("success");
  };

  const handleRegisterUser = async ({ name, lastname, phone, email, password, passwordConfirmation, companyId }) => {
    setRegisterUserStatus("loading");

    const res = await apiAuth.register(name, lastname, phone, email, password, passwordConfirmation, companyId);

    if (res.response) {
      const messages = res.response?.data?.message;
      const errors = [];
      setRegisterUserStatus("error");
      if (messages) {
        Object.keys(messages).map((k) => {
          errors.push(messages[k][0]);
        });
      }
      setRegisterUserError(errors);
      return;
    }

    setRegisterUserStatus("success");
    dispatch(loginThunk({ email, password }));
  };

  return (
    <SignUpComponent
      onSubmit={onSubmit}
      countries={countries}
      registeredCompany={registeredCompany}
      invitedUser={invitedUser}
      checkCompany={handleCheckCompany}
      registerCompany={handleRegisterCompany}
      registerUser={handleRegisterUser}
      searchCompanyStatus={searchCompanyStatus}
      registerCompanyStatus={registerCompanyStatus}
      registerUserStatus={registerUserStatus}
      registerCompanyError={registerCompanyError}
      registerUserError={registerUserError}
      showBillingAddress={showBillingAddress}
    />
  );
}

export default SignUp;
